<template>
    <section class="header-user-slice">
        <div v-if="!user || !user.connected" class="unlogged-section">
            <a ref="nofollow" class="sign-up-button" href="/who-are-you" data-testid="navbar-signup-cta">
                {{ slice.primary.signUpText }}
            </a>
            <a ref="nofollow" class="sign-in-button" href="/signin" data-testid="navbar-signin-cta">
                {{ slice.primary.signInText }}
            </a>
        </div>
        <div v-else class="logged-section">
            <div v-for="(link, i) in slice.items" :key="`slice-link-${i}`" class="link">
                <ServerSideNavigationPrismicLink :field="link.loggedInLink" class="header-link">
                    {{ link.loggedInTextLink }}
                </ServerSideNavigationPrismicLink>
            </div>
            <ServerSideNavigationPrismicLink :field="slice.primary.userDashboardLink" class="dashboard-link">
                <div class="user">
                    <div class="user-image">
                        <MaltImg v-if="image" :src="image" alt="user-photo" loading="lazy" height="30" width="30" fit="cover" />
                        <VJoyAvatar v-else size="small" :full-name="fullname" />
                    </div>
                    <div class="user-identity-text">
                        <span class="user-names" data-testid="header-user-names">
                            {{ fullname }}
                        </span>
                        <span v-if="!isCompany">
                            {{ slice.primary.userDashboardText }}
                        </span>
                    </div>
                </div>
            </ServerSideNavigationPrismicLink>
        </div>
    </section>
</template>

<script setup lang="ts">
    import {MaltImg} from '#components';
    import {computed} from 'vue';
    import {storeToRefs} from 'pinia';
    import {getSliceComponentProps} from '@prismicio/vue';
    import type {HeaderUserSliceSlice} from '@prismicio-types';
    import ServerSideNavigationPrismicLink from '@navbar-unlogged/components/ServerSideNavigationPrismicLink.vue';
    import {useUser} from '@navbar-unlogged/store/user.store';
    import {VJoyAvatar} from '@maltjoy/core-vue';

    defineProps(getSliceComponentProps<HeaderUserSliceSlice>());

    const {user, isCompany} = storeToRefs(useUser());

    const fullname = computed(() => user.value?.username);

    const image = computed(() => {
        const url = user.value?.photo?.absoluteUrl;
        return !url ? '' : url;
    });
</script>

<style lang="scss" scoped>
    @use '@malt/joy-entrypoint/src/sass/base/2_mixins/media-queries/media-queries' as mq;
    @use '@navbar-unlogged/scss/variables.scss' as vars;

    .header-user-slice {
        a {
            text-decoration: none;
        }

        font-family: var(--joy-font-family);

        a.sign-up-button {
            text-decoration: none;
            @include vars.button-primary-red;
            @include vars.button-small;

            @include mq.screen_md {
                width: 100%;
            }
            .theme-strategy & {
                @include vars.button-primary-blue;
                @include vars.button-small;
            }
        }

        a.sign-in-button {
            text-decoration: none;
            @include vars.link;
            margin-left: 16px;
            font-size: var(--joy-font-size-primary-300);
            line-height: 1.25;

            @include mq.screen_md {
                margin: 0;
                transform: translateX(calc(13px - 25vw));
            }
        }

        @include vars.flex-row-center;

        @include mq.screen_md {
            width: 100%;
            margin: 0;
        }

        .header-link {
            color: var(--joy-color-neutral-50);
            font-size: var(--joy-font-size-primary-300);
        }

        .unlogged-section {
            @include vars.flex-row-center;

            @include mq.screen_md {
                width: 100%;
                flex-direction: column;
                gap: 30px;
            }
        }

        .logged-section {
            @include vars.flex-row-center;
            gap: 24px;

            @include mq.screen_md {
                margin-top: 32px;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                padding-bottom: 32px;
                border-bottom: solid 1px var(--joy-color-neutral-30);

                a {
                    width: 100%;
                    font-size: var(--joy-font-size-primary-400);
                    margin-left: 0;
                }

                .dashboard-link {
                    order: -1;
                    padding-bottom: 32px;
                    border-bottom: solid 1px var(--joy-color-neutral-30);
                    margin-bottom: 8px;
                    font-size: var(--joy-font-size-primary-600);
                }
            }
        }

        .user {
            display: flex;
            text-align: left;
            align-items: center;

            .user-identity-text {
                display: flex;
                flex-direction: column;
            }

            background-color: transparent;
            color: var(--joy-color-neutral-60);
            border: none;
            line-height: vars.$line-height-xl;
            color: var(--joy-color-neutral-60);
            position: relative;
            font-size: var(--joy-font-size-primary-200);
            padding: 0;

            &:hover,
            &:focus,
            &:active,
            &:focus {
                color: var(--joy-color-primary-70);
                border: none;
                box-shadow: none;
            }

            &:after {
                background: none repeat scroll 0 0 transparent;
                background: var(--joy-color-primary-30);
                content: '';
                display: block;
                left: 50%;
                position: absolute;
                bottom: -13px;
                width: 0;
                transition: width var(--joy-transition-duration-long) var(--joy-transition-timing-function),
                    var(--joy-transition-duration-long) var(--joy-transition-timing-function);
                width: 0;
                height: 4px;
                border-radius: 4px;
            }

            &:hover:after {
                width: 100%;
                left: 0;
            }

            img {
                min-width: 100%;
                height: 100%;
                border-radius: 50%;
                object-fit: cover;
            }

            .user-image {
                width: 30px;
                height: 30px;
                margin-right: 8px;
            }

            .user-names {
                font-weight: var(--joy-font-weight-bold);
            }
        }

        .link {
            padding: 0 12px;

            @include mq.screen_md {
                padding: 0;
                width: 100%;
                display: flex;
            }
        }
    }
</style>
